import request from '../utils/request'
 /*
 * ============新增账户
 * @params{
 *
 * addAccountVo:{
 *      bankaddress: string      开户行
 *      bankname:string          银行名称
 *      name:string              姓名
 *      no:string                账号
 *      type:init                充值类型
 *      status:init              状态
 *     }
 * }
 * */
 //列表
export function strategyData(data) {
    return request({
        url:'/platform/cms/strategy/data',
        method:'post',
        data
    })
}
//查询作者
export function listWorker(data) {
    return request({
        url:'/platform/app/user/listWorker',
        method:'post',
        data
    })
}
//查询英雄
export function listHero(data) {
    return request({
        url:'/platform/cms/hero/list',
        method:'post',
        data
    })
}
//攻略修改
export function strategyEdit(data) {
    return request({
        url:'/platform/cms/strategy/editDo',
        method:'post',
        data
    })
}
// 攻略新增
export function strategyAdd(data) {
    return request({
        url:'/platform/cms/strategy/addDo',
        method:'post',
        data
    })
}
//攻略删除
export function strategyDelete(data) {
    return request({
        url:'/platform/cms/strategy/delete?ids='+[data],
        method:'post',
        // data
    })
}
