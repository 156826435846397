<template>
    <el-container class="page-container">
        <el-main class="page-main" style="padding: 0 20px">
            <br>
            <el-form
                    label-position="left"
                    label-width="70px"
                    :model="query"
                    ref="searchForm"
            >
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item prop="name" label="攻略名称">
                            <el-input v-model="query.name" clearable placeholder="请输入攻略名称" class="inner-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item prop="workerName" label="作者">
                            <el-input v-model="query.workerName" clearable placeholder="请输入作者" class="inner-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="0">
                            <el-button type="primary" @click="handleSearch">
                                <i class="cw-icon cw-icon-sousuo"></i>搜索
                            </el-button>
                            <el-button type="primary" plain @click="clearSearch">
                                <i class="cw-icon cw-icon-zhongzhi"></i>重置
                            </el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24">
                        <el-form-item label-width="0">
                            <el-button type="primary" @click="handleAdd">
                                新增
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-table :data="tableData" style="width: 100%" border>
                <!--                <el-table-column type="selection" width="55"> </el-table-column>-->
                <el-table-column prop="name" label="攻略名称" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="content" label="攻略内容">
                    <template slot-scope="scope">
                        <!--                        <img :src="scope.row.content" :alt="scope.row.content" class="shop-image">-->
                        <el-image
                                class="shop-image"
                                :src="scope.row.content"
                                :alt="scope.row.content"
                                :preview-src-list="[scope.row.content]">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="foldContent" label="攻略图">
                    <template slot-scope="scope">
                        <!--                        <img :src="scope.row.foldContent" :alt="scope.row.foldContent" class="shop-image">-->
                        <el-image
                                class="shop-image"
                                :src="scope.row.foldContent"
                                :alt="scope.row.foldContent"
                                :preview-src-list="[scope.row.foldContent]">
                        </el-image>
                    </template>
                </el-table-column>

                <!--                <el-table-column-->
                <!--                        prop="earlyStage"-->
                <!--                        label="前期过渡"-->
                <!--                        :show-overflow-tooltip="true"-->
                <!--                >-->
                <!--                </el-table-column>-->
                <!--                <el-table-column-->
                <!--                        prop="searchWay"-->
                <!--                        label="搜牌节奏"-->
                <!--                        :show-overflow-tooltip="true"-->
                <!--                >-->
                <!--                </el-table-column>-->
                <!--                <el-table-column-->
                <!--                        prop="equip"-->
                <!--                        label="装备分配"-->
                <!--                        :show-overflow-tooltip="true"-->
                <!--                >-->
                <!--                </el-table-column>-->
                <!--                <el-table-column-->
                <!--                        prop="formation"-->
                <!--                        label="阵型占位"-->
                <!--                        :show-overflow-tooltip="true"-->
                <!--                >-->
                <!--                </el-table-column>-->
                <!--                <el-table-column-->
                <!--                        prop="restrain"-->
                <!--                        label="克制分析"-->
                <!--                        :show-overflow-tooltip="true"-->
                <!--                >-->
                <!--                </el-table-column>-->
                <el-table-column
                        prop="workerName"
                        label="作者"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column fixed="right" width="160" label="操作">
                    <template slot-scope="scope">
                        <el-button
                                size="mini"
                                type="success"
                                @click="handleEdit(scope.row)"
                        >修改
                        </el-button
                        >
                        <el-button
                                size="mini"
                                type="danger"
                                @click="handleDelete(scope.row)"
                        >删除
                        </el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <br/>
            <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.totalCount"
            >
            </el-pagination>
        </el-main>
        <form-dialog
                @opened="initEdit"
                @close="handleClose('addForm')"
                :title="title"
                :dialogVisible="visibleAddDialog"
                width="750px"
        >
            <template slot="content">
                <el-form
                        :model="formData"
                        ref="addForm"
                        :rules="formRules"
                        label-position="right"
                        label-width="80px"
                >

                    <el-form-item prop="name" label="攻略名称">
                        <el-input maxlength="100" placeholder="攻略名称"
                                  v-model="formData.name"
                                  auto-complete="off" tabindex="2" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="content" label="攻略内容">
                        <label class="avatar-uploader" @change="uploadSuccess">
                            <input type="file" style="display: none;visibility: hidden" accept="image/*">
                            <input type="hidden" v-model="formData.content">
                            <img v-if="formData.content" :src="formData.content" class="avatar">
                            <i v-else class="cw-icon cw-icon-tupian"></i>
                        </label>
                    </el-form-item>
                    <el-form-item prop="content" label="攻略图">
                        <label class="avatar-uploader" @change="uploadSuccessfoldContent">
                            <input type="file" style="display: none;visibility: hidden" accept="image/*">
                            <input type="hidden" v-model="formData.foldContent">
                            <img v-if="formData.foldContent" :src="formData.foldContent" class="avatar">
                            <i v-else class="cw-icon cw-icon-tupian"></i>
                        </label>
                    </el-form-item>
                    <el-form-item prop="textContent" label="自定义">
                        <div id="edit"></div>
                    </el-form-item>
                    <el-form-item prop="heroList" label="英雄">
                        <el-select v-model="formData.heroList" placeholder="英雄"
                                   multiple
                                   filterable
                                   default-first-option style="width: 100%;">
                            <el-option v-for="(item,index) in heroList" :key="index" :label="item.title"
                                       :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="workerId" label="作者">
                        <el-select v-model="formData.workerId" placeholder="作者" style="width: 100%;">
                            <el-option v-for="(item,index) in siteDataArr" :key="index" :label="item.nickname"
                                       :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button type="primary" @click="doAdd">确 定</el-button>
                        <el-button @click="visibleAddDialog=false">取 消</el-button>
                    </div>
                </el-form>
            </template>
        </form-dialog>
    </el-container>
</template>

<script>
    import {mapGetters} from "vuex";
    import formDialog from "../../components/formDialog";
    import {strategyData, listWorker, listHero, strategyEdit, strategyAdd, strategyDelete} from "../../api/strategy";
    import {uploadImg} from "../../api/activeManage";

    import E from 'wangeditor'

    export default {
        name: "strategyOptions",
        components: {formDialog},
        data() {
            let validateBusinessEnd = (rule, value, callback) => {
                if (!this.formData.businessEnd || !value) {
                    callback(new Error('营业时间'));
                } else {
                    callback();
                }
            };
            let validatemobile = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('联系电话'));
                } else if ((!(/^1[3456789]\d{9}$/.test(value)))) {
                    callback(new Error('请输入正确的联系电话'));
                } else {
                    callback();
                }
            };
            return {
                tableData: [],
                pageInfo: {
                    totalCount: 0,
                    pageNumber: 1,
                    pageSize: 10,
                },
                query: {
                    name: "",
                    workerName: ''
                },
                visibleFormDialog: false,
                visibleAddDialog: false,
                formData: {
                    name: '',
                    content: '',
                    foldContent: '',
                    textContent: '',
                    // earlyStage:'',
                    // searchWay:'',
                    // equip:'',
                    // formation:'',
                    // restrain:'',
                    heroList: '',
                    workerId: '',
                },
                formRules: {
                    name: [
                        {required: true, message: '攻略名称', trigger: 'blur'}
                    ],
                    content: [
                        {required: true, message: '攻略内容', trigger: 'blur'}
                    ],
                    foldContent: [
                        {required: true, message: '攻略图', trigger: 'blur'}
                    ],
                    earlyStage: [
                        {required: true, message: '前期过渡', trigger: 'blur'}
                    ],
                    searchWay: [
                        {required: true, message: '搜牌节奏', trigger: 'blur'}
                    ],
                    equip: [
                        {required: true, message: '装备分配', trigger: 'blur'}
                    ],
                    formation: [
                        {required: true, message: '阵型占位', trigger: 'blur'}
                    ],
                    restrain: [
                        {message: '克制分析', trigger: 'blur'}
                    ],
                    workerId: [
                        {message: '作者id', trigger: 'blur'}
                    ],
                },
                siteDataArr: [],
                heroList: [],
                title: '新增攻略'
            };
        },
        computed: {
            ...mapGetters(["userInfo"])
        },
        methods: {
            initEdit() {
                if (this.editor) {
                    this.editor.txt.html(this.formData.textContent)
                    return false
                }
                this.editor = new E('#edit')
                this.editor.customConfig.menus = [
                    'head',  // 标题
                    'bold',  // 粗体
                    'fontSize',  // 字号
                    'fontName',  // 字体
                    'italic',  // 斜体
                    'underline',  // 下划线
                    'strikeThrough',  // 删除线
                    'foreColor',  // 文字颜色
                    'backColor',  // 背景颜色
                    // 'link',  // 插入链接
                    'list',  // 列表
                    'justify',  // 对齐方式
                    'quote',  // 引用
                    'emoticon',  // 表情
                    // 'image',  // 插入图片
                    // 'table',  // 表格
                    // 'video',  // 插入视频
                    // 'code',  // 插入代码
                    'undo',  // 撤销
                    'redo'  // 重复
                ]
                this.editor.create()
                this.editor.txt.html(this.formData.textContent)
                this.editor.customConfig.onchange = function (html) {
                    this.formData.textContent = html
                    // html 即变化之后的内容
                    console.log(html)
                }
            },
            handleShowPassword() {
                this.inputType = this.inputType === "password" ? "text" : "password";
            },
            handleSearch() {
                this.pageInfo.pageNumber = 1;
                this.printsData();
            },
            async printsData() {
                try {
                    let query = Object.assign({}, this.pageInfo, this.query);
                    let formData = {};
                    for (let i in query) {
                        if (query[i] !== "" && i !== "total" && query[i] !== -1) {
                            formData[i] = query[i];
                        }
                    }
                    let resonse = await strategyData(formData);
                    let {data} = resonse;
                    this.tableData = data.list;
                    this.pageInfo.totalCount = data.totalCount;
                } catch (e) {
                    console.log(e);
                }
            },
            clearSearch() {
                this.query = {
                    searchName: "",
                    searchKeyword: ""
                }
                this.handleSearch();
            },
            closeModul() {
                this.visibleFormDialog = false;
                this.printsData();
            },
            handleSizeChange(val) {
                this.pageInfo.pageSize = val;
                this.pageInfo.page = 1;
                this.printsData();
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.pageInfo.pageNumber = val;
                this.printsData();
            },
            handleClose(form) {
                this.$refs[form].resetFields();
                this.visibleFormDialog = false;
                this.visibleAddDialog = false;
            },
            handleEdit(row) {
                this.title = '更新攻略'
                let heroList = row.heroList;
                let heroListIds = [];
                for (let m in heroList) {
                    heroListIds.push(row.heroList[m].id);
                }
                let key = ['id', 'name', 'content', 'foldContent', 'textContent', 'workerId'];
                for (let i of key) {
                    this.formData[i] = row[i];
                }
                this.formData.heroList = heroListIds;
                this.visibleAddDialog = true;
            },
            async siteData() {
                let {data} = await listWorker();
                this.siteDataArr = data;
            },
            async heroData() {
                let {data} = await listHero();
                this.heroList = data;
                // console.log(352,data)
            },
            //删除
            async handleDelete(row) {
                try {
                    await this.$confirm('删除后无法恢复，确定删除吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    let data = await strategyDelete(row.id)
                    this.$message({
                        message: data.msg,
                        type: 'success'
                    });
                    this.printsData();
                } catch (e) {

                }
            },
            //新增
            handleAdd() {
                this.title = '新增攻略'
                this.visibleAddDialog = true;
                this.formData = {
                    name: '',
                    content: '',
                    foldContent: '',
                    textContent: '',
                    heroList: '',
                    workerId: '',
                };
            },
            async uploadSuccess(e) {
                try {
                    let file = e.target.files[0];
                    let form = new FormData();
                    form.append('filedata', file);
                    let {data} = await uploadImg(form)
                    this.formData.content = data.url
                    // this.$forceUpdate();
                    // console.log(this.formData)
                } catch (e) {
                }
                // this.formData.picUrl = data.url
                // this.$forceUpdate();
            },

            async uploadSuccessfoldContent(e) {
                try {
                    let file = e.target.files[0];
                    let form = new FormData();
                    form.append('filedata', file);
                    let {data} = await uploadImg(form)
                    this.formData.foldContent = data.url
                    // this.$forceUpdate();
                    // console.log(this.formData)
                } catch (e) {
                }
                // this.formData.picUrl = data.url
                // this.$forceUpdate();
            },

            async doAdd() {
                this.formData.textContent = this.editor.txt.html()
                this.$refs["addForm"].validate(async valid => {
                    if (valid) {
                        try {
                            let formData = Object.assign({}, this.formData)
                            formData.heroList = formData.heroList.toString()
                            if (formData.id) {
                                await strategyEdit(formData);
                                this.$message({
                                    type: "success",
                                    message: "更新成功!"
                                });
                            } else {
                                await strategyAdd(formData);
                                this.$message({
                                    type: "success",
                                    message: "操作成功!"
                                });
                            }
                            this.visibleAddDialog = false;
                            this.printsData();
                        } catch (e) {
                        }
                    }
                });
            },
        },
        mounted() {
            this.printsData();
            this.siteData();
            this.heroData();
        }
    };
</script>

<style lang="less">
    .btn-group {
        button {
            margin-left: 20px;
        }
    }

    .el-input--prefix .el-input__inner {
        padding-left: 46px;
    }

    .el-input__prefix {
        left: 15px;
        color: #303133;
        i {
            font-style: normal;
        }
    }

    .icon-cursor {
        cursor: pointer;
        margin-right: 10px;
        &:hover {
            color: #348eed;
        }
    }

    .shop-image {
        width: 48px;
        height: 48px;
        border-radius: 4px;
    }

    .avatar-uploader {
        height: 70px;
        width: 70px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed #dcdcdc;
        border-radius: 4px;
        cursor: pointer;
        &.el-upload:hover {
            border-color: #409EFF;
        }
        .cw-icon-tupian {
            font-size: 28px;
            color: #8c939d;
        }
        .el-upload {
            height: 100%;
            display: flex !important;
            justify-content: center;
            align-items: center;
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .avatar {
            height: 100%;
            width: 100%;
            display: block;
        }
    }
</style>
